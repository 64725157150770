<template>
  <div class="main">
    <div class="content" v-if="orderData !== '{}'">
      <div class="title">
        <span>基本信息</span>
      </div>
      <div class="box">
        <div class="info-image">
          <el-image
            style="width: 80px; height: 80px"
            :src="`${orderData.productImageUrl}`"
            fit="fit"
          ></el-image>
        </div>
        <div class="basic-info-box">
          <div class="info-box">
            <div class="info-item">
              <span>订单编号：{{ orderData.orderNum }} </span>
            </div>
            <div class="info-item">
              <span
                >订单状态：
                <span v-if="isRefund">
                  <span v-if="refundType === 2">退款中</span>
                  <span v-else-if="refundType === 1">退款失败</span>
                  <span v-else-if="refundType === 0">退款成功</span>
                  <!-- <span>{{ refundType === 2 ? "退款失败" : "退款中" }}</span> -->
                </span>
                <span v-else>{{
                  $dict.orderState | dictFormat(orderData.shippingState)
                }}</span>
              </span>
            </div>
            <div class="info-item">
              <span>下单时间：{{ orderData.createTime || '-' }}</span>
            </div>
            <div class="info-item">
              <span>昵称：{{ orderData.nickName }} </span>
            </div>
            <div class="info-item">
              <span>付款时间：{{ orderData.payTime || '-' }}</span>
            </div>
            <div class="info-item">
              <span
                >支付方式：{{
                  $dict.paymentType | dictFormat(orderData.paymentChannel)
                }}</span
              >
            </div>
            <div class="info-item">
              <span>收货人姓名： {{ orderData.consigneeName }}</span>
            </div>
            <div class="info-item">
              <span>收货人联系电话： {{ orderData.consigneePhone }}</span>
            </div>
            <div class="info-item">
              <span
                >收货地址：{{ orderData.province }}{{ orderData.city
                }}{{ orderData.county }}{{ orderData.detailed }}</span
              >
            </div>
            <div class="info-item">
              <span
                >商家拒绝退款理由:{{
                  orderData.businessCheckDetail || '-'
                }}</span
              >
            </div>
            <div
              class="info-item"
              v-if="orderData.shippingState >= 4 && refundType === 0"
            >
              <span>物流信息：{{ orderData.emsname || orderData.emsId }}</span>
              <span style="padding: 0 8px">{{ orderData.shipNumber }}</span>
              <template>
                <el-button
                  v-if="orderData.shippingState === 4"
                  size="small"
                  type="text"
                  @click="editWuliuInfo"
                >
                  编辑物流
                </el-button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <span>商品信息</span>
      </div>
      <div>
        <el-table
          :data="orderData.items"
          show-summary
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
          border
        >
          <el-table-column
            prop="productId"
            label="商品ID"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column label="商品名称" width="auto" align="center">
            <template slot-scope="scope">
              <span
                style="color: #ff5000; cursor: pointer"
                @click="toGoodsDetails(scope.row)"
              >
                {{ scope.row.productName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="typeName"
            label="专区类别"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="slb"
            label="价格"
            width="auto"
            align="center"
            v-show="orderData.items.remark == '秒杀订单'"
          >
          </el-table-column>
          <el-table-column
            prop="price"
            label="价格"
            width="auto"
            align="center"
            v-show="orderData.items.remark != '秒杀订单'"
          >
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="productDetailGroupName"
            label="规格"
            width="auto"
            align="center"
          >
          </el-table-column>
          <el-table-column label="小计" prop="amount" align="center">
          </el-table-column>
        </el-table>
      </div>
      <div class="title">
        <span>买家留言</span>
      </div>
      <div>
        <span>{{ orderData.buyerMessage }}</span>
      </div>
    </div>
    <!-- goodsDetalisVisible -->
    <el-dialog
      title="商品信息"
      :visible.sync="goodsDetalisVisible"
      :append-to-body="true"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="商品图片">
          <div style="display: flex">
            <div
              v-for="(item, index) in goodsData.imageList"
              :key="index"
              class="shoplistimg"
            >
              <el-image
                fit="cover"
                style="width: 80px; height: 80px; margin-right: 4px"
                :src="item.imageUrl"
              ></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="名称">
          <span>{{ goodsData.name }}</span>
        </el-form-item>
        <el-form-item label="分类">
          <span
            >{{ goodsData.productTypeParentName }}/{{
              goodsData.productTypeName
            }}</span
          >
        </el-form-item>
        <el-form-item label="专区">
          <span v-if="goodsData.isOne === 1">一号专区商品</span>
          <span v-else-if="goodsData.isOne === 2">普通商品</span>
        </el-form-item>
        <el-form-item label="规格">
          <el-table
            :data="goodsData.repositoryList"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
            border
            style="width: 100%"
          >
            <el-table-column prop="productName" label="规格" width="auto">
            </el-table-column>
            <el-table-column label="价格" width="auto">
              <template slot-scope="scope">
                <span v-if="scope.row.price">￥{{ scope.row.price }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="count" label="库存"> </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="配送">
          <el-checkbox-group v-model="goodsData.deliveryType">
            <el-checkbox label="2" name="deliveryType" disabled
              >物流</el-checkbox
            >
            <el-checkbox label="1" name="deliveryType" disabled
              >自提</el-checkbox
            >
            <el-checkbox label="3" name="deliveryType" disabled
              >配送</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="详情">
          <div class="goods-details" v-html="goodsData.detail"></div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改物流dialog -->
    <!-- 发货 -->
    <el-dialog
      title="编辑物流"
      :visible.sync="deliverDialogVisible"
      width="30%"
      @close="deliverDialogClose"
      v-if="deliverDialogVisible"
      :modal="false"
    >
      <div>
        <el-form ref="deliverForm" :model="deliverForm" :rules="rules">
          <el-form-item>
            <p class="noticeTxt">完善物流公司信息后，请'添加物流单号'</p>
          </el-form-item>
          <el-form-item label="物流公司名称" prop="emsId" label-width="120px">
            <el-select
              v-model="deliverForm.emsId"
              placeholder="请选择"
              style="width: 280px"
              filterable
              allow-create
            >
              <el-option
                v-for="item in logisticsList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
            <el-button @click="addDomain" type="primary" class="maginBtn"
              >添加物流单号</el-button
            >
          </el-form-item>

          <el-form-item
            label-width="120px"
            v-for="(domain, index) in deliverForm.domains"
            :label="'物流单号' + (index + 1)"
            :key="domain.key"
            :prop="'domains.' + index + '.value'"
            :rules="{
              required: true,
              message: '物流单号不能为空',
              trigger: 'blur',
            }"
          >
            <el-input v-model="domain.value" style="width: 60%"></el-input
            ><el-button @click.prevent="removeDomain(domain)" class="maginBtn"
              >删除</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliverDialogClose">取 消</el-button>
        <el-button type="primary" @click="deliverSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
    isRefund: {
      type: Boolean,
      default: () => false, //是否是退款订单详情  默认全部订单
    },
    isSharedGoods: {},
    refundType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      goodsDetalisVisible: false,// 商品详情弹窗
      goodsData: [],// 商品详情
      deliverDialogVisible: false, //发货弹窗
      logisticsList: [], //物流列表
      // 发货表单
      deliverForm: {
        emsId: null,// 物流公司
        emsNum: null,// 物流单号
        domains: [],// 物流单号
      },
      // 校验规则
      rules: {
        emsId: [
          {
            required: true,
            message: '请选择物流公司',
            trigger: 'change',
          },
        ],

      },
    };
  },
  created() {
    this.getLogisticsList();
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    // 查看物流
    logisticsOpen(data) {
      this.$emit('logisticsOpen');
    },
    logisticsRevise(data) {
      this.$emit('logisticsRevise');
    },
    toGoodsDetails(item) {
      let resData = {
        productId: item.productId,
      };
      this.$api.goods.getSpecInfoById(resData).then((res) => {
        res.data.deliveryType = res.data.deliveryType.split(',');

        this.goodsData = res.data ? res.data : [];
        this.goodsDetalisVisible = true;
      });
    },

    /* 编辑物流 */
    editWuliuInfo() {
      this.deliverForm.domains = [];
      // 打开编辑物流弹层
      this.deliverDialogVisible = true;
      // 赋值
      this.deliverForm.emsId = this.orderData.emsname || this.orderData.emsId;
      // 物流单号
      let str = this.orderData.shipNumber;
      let orderArr;
      if (str) {
        orderArr = str.split(',');
        orderArr.forEach((item) => {
          this.deliverForm.domains.push({
            value: item,
            key: item,
          });
        });
      } else {
        orderArr = [];
      }
    },
    /* 编辑物流弹层关闭 */
    deliverDialogClose() {
      // 发货弹窗关闭
      // this.$refs["deliverForm"].resetFields();
      Object.keys(this.deliverForm).forEach((item) => {
        this.deliverForm[item] = null;
      });
      this.deliverForm.domains = [];
      this.deliverDialogVisible = false;
    },

    /* 提交新物流表单 */
    // 发货提交
    deliverSubmit() {
      this.$refs['deliverForm'].validate(async (valid) => {
        if (valid) {
          let emsNumArrat = [];
          this.deliverForm.domains.forEach((item) => {
            emsNumArrat.push(item.value);
          });
          let resData = {
            emsId: this.deliverForm.emsId,
            emsNum: emsNumArrat.toString(),
            orderId: this.orderData.id,
          };
          if (!resData.emsNum) {
            this.$message({
              type: 'info',
              message: '请添加物流单号',
            });
            return;
          }
          await this.$api.order.confirmShopOrder(resData).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '修改成功',
              });
              // 重新加载数据
              this.$api.order
                .orderDetails({
                  id: this.orderData.id,
                  isSharedGoods: this.isSharedGoods,
                })
                .then((res) => {
                  this.$emit('update:orderData', res.data);
                  this.deliverDialogClose();
                });
            } else {
              this.$message.error(res.message);
            }
            this.deliverDialogClose();
          });
        }
      });
    },

    /* 获取物流公司列表 */
    getLogisticsList() {
      this.$api.order.slbemsEmsList().then((res) => {
        // console.log(res);
        if (res.success) {
          this.logisticsList = res.data ? res.data : [];
        }
      });
    },

    //新增多个物流单号
    addDomain() {
      this.deliverForm.domains.push({
        value: '',
        key: Date.now(),
      });
    },

    // 删除物流单号
    removeDomain(item) {
      var index = this.deliverForm.domains.indexOf(item);
      if (index !== -1) {
        this.deliverForm.domains.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: calc(100vh - 72px);
  padding: 32px;
  overflow-y: auto;
  background: #c0c4cc;
}
.noticeTxt {
  color: #ff5000;
}
.content {
  background: #fff;
  padding: 24px;
  border-radius: 4px;

  .title {
    font-weight: bold;
    margin: 16px 0;
    color: #1f2f3d;
    font-size: 14px;
  }
}
.basic-info-box {
  display: flex;
  align-items: center;

  .info-box {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .info-item {
      margin: 8px 0;
      width: 45%;
    }
  }
}

.maginBtn {
  margin-left: 20px;
}
.goods-details {
  ::v-deep img {
    width: 100%;
  }
}
</style>
